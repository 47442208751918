import React from 'react'

import successImg from "../../images/check-circle-1.svg"

const Success = (props) => {

    const { lead } = props;

    return (
        <div className='content m-3 mb-4'>
            <div className='row'>
                <div className='col-2 success-contact'><img src={successImg}></img></div>
                <div className='col-10 p-0 ps-2'>
                    <h1 className='text text-dark fw-bold fs-5 mb-1'>Scan erfolgreich</h1>
                    <p className='text text-dark fw-semibold fs-6 mb-0'>{lead?.firstname} {lead?.lastname}</p>
                    <p className='text text-secondary fs-6 mb-0'>{lead?.email}</p>
                </div>
            </div>
        </div>
    )
}

export default Success