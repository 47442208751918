import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

import UseStateContext from './session/UseStateContext'
import Navbar from './components/Navbar';
import Banner from './components/Banner';

import errorImg from "../images/error-o.svg";
import successImg from "../images/check-circle-1.svg";

import { api, getTicketTypeById, getTicketTypeInfosByTicketTypeId, getAddressOfOrder, getTicketById } from 'ticketino-api-client';
import Success from './components/Success';
import Header from './components/Header';
import Loading from './components/Loading';

const Contact = () => {

    const { context, resetContext, setContext } = UseStateContext();

    const [lead, setLead] = useState({});
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [sessionError, setSessionError] = useState(false);

    let navigate = useNavigate();
    let baseUrl = "https://api.ticketino.com";

    const {
        salutation,
        firstname,
        lastname,
        company,
        companyFunction,
        street,
        postCode,
        city,
        telephone,
        email,
        projectRequest
    } = lead;

    const { uniqueKey } = useParams();

    useEffect(() => {
        if (!(uniqueKey > 0)) {
            setError(true);
        }
    }, [uniqueKey]);

    useEffect(() => {
        setContext({ uniqueKey: uniqueKey });

        loadToken()
    }, [uniqueKey]);

    const loadToken = async () => {
        await axios.get("Ticketino/Token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.data;

            if (uniqueKey > 0) {
                loadTicket(uniqueKey);
            }
        }).catch((error) => {
            logout();
            setError(true);
            setLoading(false);
        })
    }

    const loadTicket = async (uniqueKey) => {
        try {
            setLoading(true);
            setError(false);

            const ticket = await requestTicketByUniqueKey(uniqueKey);
            
            const ticketTypeInfo = await getTicketTypeInfosByTicketTypeId(ticket.ticketTypeId);

            const ticketTypeName = ticketTypeInfo.find(tti => tti.languageId == 2)?.name;

            let updatedLead = {
                salutation: ticket.salutation,
                firstname: ticket.firstname,
                lastname: ticket.lastname,
                company: ticket.company,
                companyFunction: ticket.option11,
                street: ticket.street,
                postCode: ticket.postCode,
                city: ticket.city,
                telephone: ticket.telephone,
                email: ticket.email,
                customerNumber: ticket.option10,
                status: ticket.option11,
                ticketId: ticket.id,
                ticketName: ticketTypeName
            }

            const orderAddress = await getAddressOfOrder(ticket.orderId);

            for (let property in updatedLead) {
                if (updatedLead[property] == null) {
                    if (orderAddress[property] != null) {
                        updatedLead[property] = orderAddress[property]
                    }
                }
            }

            if (updatedLead.salutation == "Sehr geehrter Herr") {
                updatedLead.salutation = "Herr";
            } else {
                updatedLead.salutation = "Frau"
            }

            setLead(updatedLead);
            setLoading(false);
        }
        catch (error) {
            setError(true);
            setLoading(false);
            console.error(error);
        }
    }

    const requestTicketByUniqueKey = async (uniqueKey) => {
        try {
            const res = await axios.get(`${baseUrl}/Ticket?uniqueKey=${uniqueKey}`);
            return res.data;
        } catch (error) {
            console.error(error);
        }
    }

    const logout = () => {
        axios.post("/Logout").then(() => {
            resetContext();
            navigate("/login");
        })
    }

    const onInputChange = (e) => {
        setLead({ ...lead, [e.target.name]: e.target.value });
    };

    const onSubmit = () => {
        let updatedLead = {
            exhibitorId: context.exhibitor?.id,
            standNumber: context.exhibitor?.standNumber,
            company: company,
            salutation: salutation,
            firstname: firstname,
            lastname: lastname,
            companyFunction: companyFunction,
            phone: telephone,
            email: email,
            street: street,
            postCode: postCode,
            region: city,
            projectRequest: projectRequest,
            customerNumber: lead.customerNumber,
            status: lead.status,
            ticketId: lead.ticketId,
            scanner: context.scanner,
            ticketName: lead.ticketName
        }

        setContext({ lead: updatedLead });

        if (projectRequest) {
            navigate("/project");
        } else {
            navigate("/information");
        }
    }

    // validating input fields
    let validation = typeof projectRequest == 'boolean' && !error;

    return (
        <>
            <Navbar />
            <Banner />
            <div className='container page-container'>
                <Header />
            </div>
            {!loading ? <div className='container mt-4 page-container'>

                {(error && uniqueKey != 0) ? <div className='content m-3 mb-4'>
                    <div className='row'>
                        <div className='col-2 success-contact'><img src={errorImg}></img></div>
                        <div className='col-10 p-0 ps-2'>
                            <h1 className='text text-dark fw-bold fs-5 mb-1'>Scan war nicht erfolgreich</h1>
                            <p className='text text-secondary fs-6 mb-0'>{sessionError ? "Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein." : "Die Ticketnummer existiert nicht oder ist ungültig"}</p>
                        </div>
                    </div>
                </div> : <Success lead={lead} />}

                {!(error && uniqueKey != 0) && <div className='content m-3 mb-4'>
                    <h3 className='text text-dark fw-semibold fs-5 mb-2'>Art der Anfrage</h3>

                    <p className='text text-secondary fs-6 mb-3'>Bitte wählen Sie die Art der Anfrage. Sie werden anschliessend auf das richtige Formular weitergeleitet.</p>

                    <div className='mb-4'>
                        <form>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" checked={projectRequest == true} onClick={() => setLead({ ...lead, projectRequest: true })} />
                                <label className="form-check-label text-secondary p-1 ms-3">
                                    Projektanfrage
                                </label>
                            </div>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" checked={projectRequest == false} onClick={() => setLead({ ...lead, projectRequest: false })} />
                                <label className="form-check-label text-secondary p-1 ms-3">
                                    Aufnahme als Neukunde, Informationsmaterial oder Kundenbesuch
                                </label>
                            </div>
                        </form>
                    </div>
                    <button className='button' onClick={onSubmit} disabled={!validation}>Bestätigen</button>
                </div>}
            </div> : <Loading />}
        </>
    )
}

export default Contact