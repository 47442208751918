import React from 'react'
import "../../style.css";

import banner from "../../images/banner.png"
import logo from "../../images/ticketino-logo.png";

const Banner = () => {
    return (
        <div className='container banner-container'>
            <div className='row m-0'>
                <div className='col-12 text-center'>
                    <img className='img-fluid ticketino-logo' src={logo}></img>
                </div>
            </div>
        </div>
    )
}

export default Banner