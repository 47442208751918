import React, { useEffect, useState } from 'react'
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { DateTimeManager } from './../Common/DateTimeManager.js';

import "react-datepicker/dist/react-datepicker.css";

import UseStateContext from './session/UseStateContext'
import Navbar from './components/Navbar';
import Banner from './components/Banner';
import { useNavigate } from 'react-router-dom';
import successImg from "../images/check-circle-1.svg";
import Success from './components/Success';
import Header from './components/Header';

const ProjectRequest = () => {
    const { context, setContext } = UseStateContext();

    const [lead, setLead] = useState({});
    const [deliveryDate, setDeliveryDate] = useState(null);

    const [products, setProducts] = useState([{ article: "", quantity: "", description: "" }, { article: "", quantity: "", description: "" }]);

    const {
        projectDescription,
        reference,
        budget
    } = lead;

    let navigate = useNavigate();

    useEffect(() => {
        if (context.lead) {
            loadLead(context.lead);
        }
    }, [context.lead]);

    const loadLead = async (lead) => {
        setLead(lead);
    }

    const onInputChange = (e) => {
        setLead({ ...lead, [e.target.name]: e.target.value });
    };

    const onSubmit = () => {

        let productList = products.map(product => `${product.article},${product.quantity},${product.description}`).join(";");

        let body = {
            ...lead,
            projectDescription: projectDescription,
            reference: reference,
            budget: budget,
            productList: productList,
            deliveryDate: new DateTimeManager(deliveryDate).getDefaultDateFormat()
        };

        axios.post(`/Lead`, body).then(() => {
            setContext({ lead: lead });
            navigate("/success");
        });
    }

    const productList = () => {
        return (
            <>
                {
                    products.map((product, index) => (
                        <div className="row">
                            <div className="col-12 text text-secondary mb-2">Artikel<span className='text text-danger'>*</span></div>
                            <div className="col-12 mb-3">
                                <input type="text" className="form-control" id="floatingInputValue" name="product" value={product?.article}
                                    onChange={(e) => {
                                        let updatedList = [...products];
                                        updatedList[index].article = e.target.value;
                                        setProducts(updatedList);
                                    }}
                                    maxLength="200" />
                            </div>
                            <div className="col-12 text text-secondary mb-2">Stückzahl<span className='text text-danger'>*</span></div>
                            <div className="col-12 mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="floatingInputValue"
                                    name="product"
                                    value={product?.quantity}
                                    onChange={(e) => {
                                        const newValue = e.target.value;
                                        if (!isNaN(newValue)) {
                                            let updatedList = [...products];
                                            updatedList[index].quantity = newValue;
                                            setProducts(updatedList);
                                        }
                                    }}
                                    pattern="[0-9]*" />
                            </div>
                            <div className="col-12 text text-secondary mb-2">Bemerkung<span className='text text-danger'>*</span></div>
                            <div className="col-12 mb-3">
                                <input type="text" className="form-control" id="floatingInputValue" name="product" value={product?.description}
                                    onChange={(e) => {
                                        let updatedList = [...products];
                                        updatedList[index].description = e.target.value;
                                        setProducts(updatedList);
                                    }}
                                    maxLength="255" />
                            </div>
                            <div className="col-12 mb-2">
                                <button className='add-btn'
                                    onClick={() => {
                                        let updatedList = [...products];
                                        updatedList.splice(index, 1);
                                        setProducts(updatedList);
                                    }}>
                                    <i className="bi bi-trash3 fs-4"></i></button>
                            </div>
                            {(index + 1) != products.length && <hr></hr>}
                        </div>
                    ))
                }
            </>
        )
    }

    let productValidation = products.every(product => (
        product?.article.length > 0 &&
        product?.quantity > 0 &&
        product?.description.length > 0
    ));
    
    let validation = (
        projectDescription?.length > 0 &&
        budget?.length > 0 &&
        reference?.length > 0 &&
        deliveryDate != null &&
        products.length > 0 &&
        productValidation
    );

    return (
        <>
            <Navbar />
            <Banner />
            <div className='container mt-4 page-container'>
                <Header />
                <Success lead={lead} />

                <div className='content m-3 mb-4'>
                    <h3 className='text text-dark fw-semibold fs-5 mb-3'>Projektbeschrieb<span className='text text-danger'>*</span></h3>
                    <div className='mb-4'>
                        <div className="form-check mb-2 p-0">
                            <textarea className="form-control"
                                placeholder="Bitte beschreiben Sie das Projekt..."
                                name='projectDescription'
                                style={{ height: "100px" }}
                                value={projectDescription} onChange={onInputChange}
                                maxLength="500"
                            />
                        </div>
                    </div>
                </div>

                <div className='content m-3 mb-4'>
                    <h3 className='text text-dark fw-semibold fs-5 mb-3'>Projektrahmen<span className='text text-danger'>*</span></h3>
                    <div className='mb-4'>
                        <div className='row'>
                            <div className='col-6'>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInputValue"
                                        name="budget"
                                        value={budget}
                                        onChange={onInputChange}
                                        maxLength="200"
                                    />
                                    <label htmlFor="floatingInputValue">Budget<span className='text text-danger'>*</span></label>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInputValue"
                                        name="reference"
                                        value={reference}
                                        onChange={onInputChange}
                                        maxLength="200" />
                                    <label htmlFor="floatingInputValue">Referenz<span className='text text-danger'>*</span></label>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div>
                                    <label htmlFor="exampleFormControlInput1" className="form-label text text-secondary fs-6 mb-3'">Ungefährer Liefertermin<span className='text text-danger'>*</span></label><br></br>
                                    <DatePicker
                                        dateFormat="dd.MM.yy"
                                        className="form-control datepicker-input"
                                        id="exampleFormControlInput1"
                                        selected={deliveryDate}
                                        onChange={(date) => setDeliveryDate(date)}
                                        autoComplete='off'
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='content m-3 mb-4'>
                    <h3 className='text text-dark fw-semibold fs-5 mb-3'>Produktliste<span className='text text-danger'>*</span></h3>
                    <div className='row'>
                        <div className='col-10'>
                            <p className='text text-secondary fs-6 mb-3'>
                                Geben Sie hier die für das Projekt relevanten Produkte an.<span className='text text-danger'>*</span>
                            </p>
                        </div>
                        <div className='col-2 text-end'>
                            <button className='add-btn'
                                onClick={() => setProducts([...products, { article: "", quantity: "", description: "" }])}>
                                <i className="bi bi-plus-square fs-4"></i>
                            </button>
                        </div>
                    </div>
                    <div className='mb-0'>
                        {productList()}
                    </div>
                </div>

                <div className='row p-3 mb-5'>
                    <button className='lead-button col-12' onClick={onSubmit} disabled={!validation}>Lead absenden</button>
                </div>
            </div>
        </>
    )
}

export default ProjectRequest