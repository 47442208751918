import React, { useState } from 'react'
import Navbar from './components/Navbar';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { DateTimeManager } from './../Common/DateTimeManager.js';

const Admin = () => {
    const [fromDate, setFromDate] = useState(() => {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() - 10);
        return newDate;
    });

    const [toDate, setToDate] = useState(new Date());

    const downloadLeads = async () => {

        let queryFromDate= new DateTimeManager(fromDate).getDefaultDateFormat()
        let queryToDate= new DateTimeManager(toDate).getDefaultDateFormat()

        await axios
            .get(`/Leads/Export?fromDate=${queryFromDate}&toDate=${queryToDate}`, {
                responseType: "blob",
            })
            .then((res) => {
                const data = window.URL.createObjectURL(res.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = "Leads_Export.xlsx";
                link.click();
            })
            .catch((error) => console.error(error));
    }

    return (
        <>
            <Navbar />
            {/* <Banner /> */}
            <div className='container mt-4 page-container'>
                <div className='content m-3'>
                    <h3 className='text text-dark text-center fw-semibold fs-3 mb-3'>Alle Leads herunterladen</h3>
                    <p className='text text-secondary text-center fs-6'>Dafür brauchen Sie Admin-Rechte</p>
                    <div className="col-12 m-0-p-0 text-center text-secondary text mb-2">Von / Bis:</div>
                    <div className='row m-0-p-0'>
                        <div className="col-6 m-0-p-0 text-center">
                            <DatePicker
                                dateFormat="dd.MM.yy"
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                className="form-control datepicker-input text-center text-secondary"
                                id="exampleFormControlInput1"
                                selected={fromDate}
                                onChange={(date) => { setFromDate(date)}}
                                autoComplete='off'
                            />
                        </div>
                        <div className="col-6 m-0-p-0 text-center">
                            <DatePicker
                                dateFormat="dd.MM.yy"
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                className="form-control datepicker-input text-center text-secondary"
                                id="exampleFormControlInput1"
                                selected={toDate}
                                onChange={(date) => { setToDate(date)}}
                                autoComplete='off'
                            />
                        </div>
                    </div>
                </div>
                <div className='container text-center'>
                    <button className='success-button' onClick={downloadLeads}>Herunterladen</button>
                </div>
            </div>
        </>
    )
}

export default Admin