import React, { useState } from 'react'
import UseStateContext from './session/UseStateContext'
import Navbar from './components/Navbar';
import Banner from './components/Banner';

import "../style.css";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {

    const { context, setContext } = UseStateContext();

    const [user, setUser] = useState({});
    const [loggedIn, setIsLoggedIn] = useState(false);
    const [error, setError] = useState("");

    const { username, password } = user;

    const [scanner, setScanner] = useState("");

    let navigate = useNavigate();

    const login = () => {
        axios.post(`/Login?username=${username}&password=${password}&returnUrl=/`).then((res) => {
            setContext({
                exhibitor: res.data,
            });

            setIsLoggedIn(true);
            setError("");
        }).catch((error) => {
            setIsLoggedIn(false);
            setError(error.response.data);
        });
    }

    const finishLogin = () => {
        setContext({
            loggedIn: true,
            scanner: scanner
        })

        navigate("/");
    }

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    let validation = scanner.length > 0;

    return (
        <>
            <Navbar />
            {/* <Banner /> */}
            <div className='login container mt-3'>
                {!loggedIn ?
                    <div className='content m-3'>
                        <h3 className='text text-dark fw-semibold fs-3 mb-3'>Anmelden</h3>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingInputValue" name="username" value={username} onChange={onInputChange} />
                            <label htmlFor="floatingInputValue">Benutzername</label>
                        </div>

                        <div className="form-floating mb-3">
                            <input type="password" className="form-control" id="floatingInputValue" name="password" value={password} onChange={onInputChange} />
                            <label htmlFor="floatingInputValue">Passwort</label>
                        </div>

                        <p className='text text-danger fs-6'>{error}</p>

                        <div className='mb-2'>
                            <button className='button mb-3' onClick={login}>Bestätigen</button>
                        </div>

                        <div className='row'>
                            <div className="col-1">
                                <i class="bi bi-info-circle text-secondary"></i>
                            </div>
                            <div className="col-11">
                                <p className='text text-secondary fs-6'>
                                Die Standnummer und das Passwort wurde den Standverantwortlichen vorgängig per Mail zugestellt. Sollten Sie die Daten verloren oder nicht erhalten haben, melden Sie sich bitte im Messebüro.
                                </p>
                            </div>
                        </div>

                    </div>
                    :
                    <div className='content m-3'>
                        <h3 className='text text-dark fw-semibold fs-3 mb-2'>Ihr Name</h3>
                        <p className='text text-secondary fs-6'>
                            Bitte geben Sie Ihren Namen ein, damit die Leads damit in Verbindung gebracht werden können. Der Name ist für die Dauer des Logins gültig.
                        </p>
                        <div className="form-floating mb-4">
                            <input type="text" className="form-control" id="floatingInputValue" name="scanner" value={scanner} onChange={(e) => setScanner(e.currentTarget.value)} />
                            <label htmlFor="floatingInputValue">Vorname und Name</label>
                        </div>

                        <p className='text text-danger fs-6'>{error}</p>

                        <div className='mb-2'>
                            <button className='button' onClick={finishLogin} disabled={!validation}>Bestätigen</button>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default Login